.gallery-box {
  position: relative;
  text-align: center;

  .gallery-box-img {
    border-radius: 10px;
  }

  .gallery-box-count {
    top: 20%;
    position: absolute;
    font-size: 25px;
    font-weight: 700;
    left: 25%;
    color: #515151;
    background-color: #cbccccba;
    margin: 0;
    padding: 0;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    text-align: center;
    line-height: 1.5;
  }

  .gallery-box-header {
    margin: 0;
    font-weight: 700;
  }
}

.receiving-approve-box {
  padding: 5px;
  margin: 5px 0;

  .receiving-approve-box-header {
    text-align: center;
    font-weight: 700;
  }
  .receiving-approve-box-row {
   padding: 0 10px;
  }
}
.offer-receiving-item-conflict{
  background-color: #D99B9BAF;
}
.offer-receiving-item-approve{
  background-color: #eef5ec;
}

.partial-product-list-content{
  &:nth-child(odd) {
    background-color: #e1e1e18a;
  }

  &:nth-child(even) {
    border-top: 1px solid #d3d1d1;
    background-color: #f4f4f4cc;
  }

  .partial-accept{
    background-color: #6C696919;
    border-right: 1px solid #d3d1d1;
    border-left: 1px solid #d3d1d1;
    text-align: center;
  }
  .partial-faulty{
    background-color: #c9c9c9;
    border-bottom: 1px solid #d3d1d1;
    text-align: center;
  }
  .partial-reject{
    background-color: #6C696919;
    border-right: 1px solid #d3d1d1;
    border-left: 1px solid #d3d1d1;
    text-align: center;
  }
}
.partial-accept-header{
  border: 1px solid #d3d1d1;
  border-radius: 5px 5px 0 0;
  text-align: center;
  padding: 10px 0;
  font-weight: 700;
}
.partial-faulty-header{
  border: 1px solid #d3d1d1;
  border-radius: 5px 5px 0 0;
  text-align: center;
  padding: 10px 0;
  font-weight: 700;
}
.partial-reject-header{
  border: 1px solid #d3d1d1;
  border-radius: 5px 5px 0 0;
  text-align: center;
  padding: 10px 0;
  font-weight: 700;
}
.partial-product-header{
  padding:10px 0 10px 15px;
  font-weight: 700;
}
md-toolbar {
	background: #2a5cdc !important;
}
.md-toolbar-dev {
	background: linear-gradient(45deg, #2a5cdc 60%, transparent 60%, #FFC107 60%, #e26e04 100%, transparent 100%, transparent) !important;
}
.md-toolbar-dev::before {
	content: "TEST"; /* Arka plan metni */
	position: absolute;
	right: 10px; /* Sağdan boşluk bırak */
	font-size: 50px;
	top: -10%;
	color: rgba(0, 0, 0, 0.3); /* Soluk arka plan metni */
	font-weight: bold;
	z-index: -1; /* İçeriğin arkasında kalmasını sağla */
}
.dev-gradient {
	background: linear-gradient(45deg, #2a5cdc 60%, transparent 60%, #FFC107 60%, #e26e04 100%) !important;
}

md-toolbar-demo {
	background: linear-gradient(45deg, #2a5cdc 60%, transparent 60%, #FFC107 60%, #e26e04 100%, transparent 100%, transparent) !important;
}
.md-toolbar-demo::before {
	content: "DEMO"; /* Arka plan metni */
	position: absolute;
	right: 10px; /* Sağdan boşluk bırak */
	font-size: 50px;
	top: -10%;
	color: rgba(0, 0, 0, 0.3); /* Soluk arka plan metni */
	font-weight: bold;
	z-index: -1; /* İçeriğin arkasında kalmasını sağla */
}
.demo-gradient {
	background: linear-gradient(45deg, #2a5cdc 60%, transparent 60%, #FFC107 60%, #e26e04 100%) !important;
}

.polypus-blue {
	background: #2a5cdc !important;
}


.sidebar-icon {
	/* theme uyumluluk için kaldırılmalı*/
	color:rgb(114 114 114) !important;
}

.md-show {
	position: absolute;
}

md-menu-content {
	/*background: #5270bd;*/
}
